import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src="img/about.jpg" className="img-fluid" alt="About us" />
          </div>
          <div className="col-md-6">
            <div className="about-text">
              <h2>Sobre nosotros</h2>
              <p>{props.data ? props.data.paragraph : "Cargando..."}</p>
              <h3>¿Por qué escogernos?</h3>
              <div className="row">
                <div className="col-sm-6">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => <li key={i}>{d}</li>)
                      : "Cargando..."}
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => <li key={i}>{d}</li>)
                      : "Cargando..."}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
