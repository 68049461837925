import React from "react";

export const Services = (props) => {
  return (
<div id="services" className="text-center">
  <div className="container">
    <div className="section-title">
      <h2>Nuestros Servicios</h2>
      <p>
        Brindamos un servicio integral, cubriendo todo lo necesario para que tu
        empresa crezca de manera segura y eficiente. ¡Nos enfocamos en que la
        tecnología trabaje para ti!
      </p>
    </div>
    <div className="row">
      {props.data
        ? props.data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-sm-6 col-md-4">
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))
        : "loading"}
    </div>
  </div>
</div>

  );
};
